@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('all.min.css');
@import url('font.css');
@import url('style.min.css');
@import url('post-2.css');
@import url('post-17.css');
@import url('post-27.css');
@import url('post-75.css');
@import url('post-77.css');
@import url('post-30.css');
@import url('style_002.min.css');
@import url('theme.min.css');
@import url('frontend-lite.min.css');
@import url('post-4.css');
@import url('frontend-lite_002.min.css');
@import url('post-364.css');
@import url('post-1376.css');
@import url('widget-call-to-action.min.css');
@import url('widget-carousel.min.css');
@import url('widget-icon-box.min.css');
@import url('widget-icon-list.min.css');
@import url('widget-nav-menu.min.css');
@import url('widget-theme-elements.min.css');
@import url('animations.min.css');
@import url('fontawesome.min.css');
@import url('solid.min.css');
@import url('brands.min.css');
@import url('elementor-icons.min.css');
@import url('post-1387.css');
@import url('post-1403.css');
@import url('global.css');

html,
body {
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

@media (min-width: 768px) {
	header nav ul li a {
		margin-inline-end: 15px;
		margin-inline-start: 15px;
	}

	header nav ul li:first-child a {
		margin-inline-start: 0;
	}

	header nav ul li:last-child a {
		margin-inline-end: 0;
	}
}

/* .calendly-badge-widget {
  bottom: 14%;
}

.calendly-badge-widget .calendly-badge-content {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-size: var(--e-global-typography-accent-font-size);
  font-weight: var(--e-global-typography-accent-font-weight);
  text-transform: var(--e-global-typography-accent-text-transform);
  font-style: var(--e-global-typography-accent-font-style);
  text-decoration: var(--e-global-typography-accent-text-decoration);
  line-height: var(--e-global-typography-accent-line-height);
  letter-spacing: var(--e-global-typography-accent-letter-spacing);
  word-spacing: var(--e-global-typography-accent-word-spacing);
  fill: var(--e-global-color-ef9a968);
  color: var(--e-global-color-ef9a968);
  border-style: solid;
  border-width: 2px;
  border-color: var(--e-global-color-primary);
  border-radius: 60px 60px 60px 60px;
  min-height: 47px;
}

.calendly-badge-widget .calendly-badge-content:hover {
  color: var(--e-global-color-primary) !important;
  background-color: var(--e-global-color-a983356) !important;
  border-color: var(--e-global-color-primary) !important;
} */

.elementor-widget-form .elementor-message {
	position: absolute;
	bottom: -35px;
	font-size: 14px;
}

.elementor-social-icons-wrapper.elementor-grid .elementor-grid-item {
	padding: 0 15px;
}

.elementor-social-icons-wrapper.elementor-grid
	.elementor-grid-item:first-child {
	padding-left: 0;
}

.elementor-social-icons-wrapper.elementor-grid .elementor-grid-item:last-child {
	padding-right: 0;
}

.swiper-container-fade .swiper-slide {
	height: 600px;
}

@media only screen and (max-width: 1024px) {
	.swiper-container-fade .swiper-slide {
		height: auto;
	}
}

.elementor-27
	.elementor-element.elementor-element-6cf5020
	> .elementor-container {
	padding: 0% 15%;
	max-width: 100%;
}

.elementor-364
	.elementor-element.elementor-element-514c6e8
	.elementor-nav-menu--main
	.elementor-item {
	font-size: 18px;
	line-height: 1.8em;
}

.prose :where(ul > li):not(:where([class~='not-prose'] *))::marker {
	color: var(--e-global-color-text);
}

article p,
article ul li {
	color: var(--tw-prose-body);
}
